import { BottomSheet, BottomSheetProps } from '@stackflow/plugin-basic-ui'
import { ActivityComponentType } from '@stackflow/react'
import React, { useCallback, useState } from 'react'

import { useBottomSheetRender, useBottomSheetRenderDispatch } from './useBottomSheet'

export type StackflowBottomSheetParams = Partial<Omit<BottomSheetProps, 'onOutsideClick' | 'children'>>

const StackflowBottomSheet: ActivityComponentType<StackflowBottomSheetParams> = ({
  params = {
    borderRadius: '0px',
  },
}) => {
  const [{ render }] = useState<ReturnType<typeof useBottomSheetRender>>(useBottomSheetRender())
  const setRenderContext = useBottomSheetRenderDispatch()

  const handleOutsideClick = useCallback(() => {
    setRenderContext({
      render: null,
    })
  }, [setRenderContext])

  if (!render) {
    return null
  }

  return (
    <BottomSheet {...params} onOutsideClick={handleOutsideClick}>
      {render()}
    </BottomSheet>
  )
}

export default StackflowBottomSheet
