import { StackflowReactPlugin } from '@stackflow/react'
import React, { createContext, useContext, useEffect, useState } from 'react'

import { STAGE } from '../../constants'

const DevtoolsContext = createContext<{
  showVConsole: (flag: boolean) => void
}>({
  showVConsole: () => {},
})

export function useDevtools() {
  return useContext(DevtoolsContext)
}

const isAlphaApp = STAGE === 'development' || STAGE === 'alpha'

if (isAlphaApp && typeof window !== 'undefined') {
  ;(async () => {
    const { default: VConsole } = await import('vconsole')
    new VConsole()
  })()
}

const DevTools: React.FCWithChildren<{ isDebug?: boolean }> = ({ children, isDebug }) => {
  const devMode = isAlphaApp || isDebug

  const [isVConsoleView, setIsVConsoleView] = useState(devMode)

  const showVConsole = (flag: boolean) => {
    if (isAlphaApp || isVConsoleView === flag) {
      return
    }

    setIsVConsoleView(flag)
  }

  useEffect(() => {
    if (!isAlphaApp && isVConsoleView) {
      ;(async () => {
        const { default: VConsole } = await import('vconsole')
        new VConsole()
      })()
    }
  }, [isVConsoleView])

  return (
    <DevtoolsContext.Provider
      value={{
        showVConsole,
      }}>
      {children}
    </DevtoolsContext.Provider>
  )
}

export function devtoolsPlugin(): StackflowReactPlugin {
  return () => ({
    key: 'devtools',
    wrapStack({ stack, initialContext }) {
      const isDebug = new URLSearchParams(initialContext.req.path).get('isDebug')

      return <DevTools isDebug={Boolean(isDebug)}>{stack.render()}</DevTools>
    },
  })
}
