import { logEvent, getReferrer } from '@daangn/local-business-analytics'
import { vars } from '@seed-design/design-token'
import { basicUIPlugin } from '@stackflow/plugin-basic-ui'
import { historySyncPlugin } from '@stackflow/plugin-history-sync'
import { preloadPlugin } from '@stackflow/plugin-preload'
import { basicRendererPlugin } from '@stackflow/plugin-renderer-basic'
import { stackflow } from '@stackflow/react'
import React from 'react'

import { localBusinessAnalyticsStackflowPlugin } from './plugins/analytics/localBusinessAnalytics'
import BottomSheet from './plugins/BottomSheetPlugin/_bottomsheet/BottomSheet'
import { bottomSheetPlugin } from './plugins/BottomSheetPlugin/bottomSheetPlugin'
import { devtoolsPlugin } from './plugins/devtoolsPlugin'
import { getAppTheme } from '../AppScreenThemeContext'
import { bridge } from '../bridge/bridge'
import { STAGE } from '../constants'
import { preloadDataMap } from '../lib/readPreloadData'

declare const window: any

const activities = {
  Index: React.lazy(() => import('../activities/Index')),
  LocalContentFeed: React.lazy(() => import('../activities/LocalContentFeedActivity')),
  BottomSheet,
}

const theme = typeof window === 'undefined' ? 'android' : getAppTheme()

const borderColor = theme === 'cupertino' ? vars.$semantic.color.divider3 : vars.$semantic.color.divider2

const localBusinessLogEvent = logEvent({
  bridge: {
    bridge,
    type: 'KARROT_BRIDGE',
  },
})

export const { Stack, useFlow } = stackflow({
  transitionDuration: 350,
  activities,
  plugins: [
    basicRendererPlugin(),
    basicUIPlugin({
      theme,
      backgroundColor: vars.$semantic.color.paperDefault,
      appBar: {
        borderColor,
        textColor: vars.$semantic.color.inkText,
        iconColor: vars.$semantic.color.inkText,
      },
    }),
    historySyncPlugin({
      routes: {
        Index: '/',
        LocalContentFeed: `/local-content-feed/:pageId`,
        BottomSheet: '/bottomsheet',
      },
      fallbackActivity: () => 'Index',
    }),
    preloadPlugin({
      loaders: {
        LocalContentFeed({ activityParams, isInitialActivity, initialContext }) {
          const key = `LocalContentFeed#${JSON.stringify(activityParams)}` as string

          if (isInitialActivity) {
            preloadDataMap[key] = {
              _t: 'ok',
              data: (initialContext as any).data,
            }
          }

          return {
            key,
          }
        },
      },
    }),
    bottomSheetPlugin(),
    localBusinessAnalyticsStackflowPlugin({
      debug: STAGE !== 'production',
      logPageView: false,
      commonPayloads: {
        referrer: typeof window !== 'undefined' ? getReferrer() : null,
      },
      logEvent: ({ name, params }) => {
        localBusinessLogEvent({
          name,
          params: {
            ...params,
          },
        })
      },
    }),
    devtoolsPlugin(),
  ],
})

export type TypeActivities = typeof activities
