import { AnalyticsEvent, localBusinessAnalyticsPlugin } from '@daangn/local-business-analytics'

export const {
  useUnregisterScreenCommonPayload,
  useRegisterScreenCommonPayloadEffect,
  useRegisterScreenCommonPayload,
  useLogEvent: useLocalBusinessLogEvent,
  plugin: localBusinessAnalyticsStackflowPlugin,
  SectionParamsProvider,
  useRegisterSectionCommonPayload,
} = localBusinessAnalyticsPlugin<
  | AnalyticsEvent<'onload_local_contents_feed', any>
  | AnalyticsEvent<
      'click_local_contents_feed_item',
      {
        item_type: 'bizPost' | 'bizAccount'
        item_id: string
      }
    >
  | AnalyticsEvent<
      'click_local_contents_feed_tab',
      {
        tab_name: string
      }
    >
  | AnalyticsEvent<
      'click_local_contents_feed_filter',
      {
        filter_name: string
        filter_value: string
      }
    >
  | AnalyticsEvent<
      'click_local_contents_feed_contents_section',
      {
        button_type: 'list' | 'more'
        section_id: number
        item_id?: string
      }
    >
  | AnalyticsEvent<
      'show_local_contents_feed_contents_section',
      {
        section_id: number
      }
    >
>()
