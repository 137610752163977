import '@seed-design/stylesheet/global.css'
import '@stackflow/plugin-basic-ui/index.css'
import '@karrotframe/tabs/index.css'
import '@daangn/sprout-components-chips/index.css'
import '@daangn/sprout-components-spinner/index.css'

import './src/styles/global.css'

import { WrapPageElementBrowserArgs } from 'gatsby'
import React from 'react'

import { AppScreenThemeProvider } from './src/AppScreenThemeContext'
import { BridgeProvider } from './src/bridge/bridge'
import { MyRelayEnvironmentProvider } from './src/relay'
import BridgeInfoProvider from './src/resources/bridgeInfoResource'
import { Stack } from './src/stackflow/stackflow'

export const wrapPageElement = ({ props: { location, data } }: WrapPageElementBrowserArgs) => {
  return (
    <>
      <BridgeProvider>
        <AppScreenThemeProvider>
          <MyRelayEnvironmentProvider>
            <React.Suspense>
              <BridgeInfoProvider>
                <Stack initialContext={{ req: { path: `${location.pathname}${location.search}` }, data }} />
              </BridgeInfoProvider>
            </React.Suspense>
          </MyRelayEnvironmentProvider>
        </AppScreenThemeProvider>
      </BridgeProvider>
    </>
  )
}
