import React, { createContext, useCallback, useContext } from 'react'

import type { StackflowBottomSheetParams } from './BottomSheet'
import { useFlow } from '../../../stackflow'

type Renderer = () => JSX.Element | null
type BottomSheetRenderContextType = {
  render: Renderer | null
}

const BottomSheetRenderContext = createContext<BottomSheetRenderContextType>({
  render: () => null,
})

const BottomSheetRenderDispatchContext = createContext<
  React.Dispatch<React.SetStateAction<BottomSheetRenderContextType>>
>(null as any)

export const BottomSheetRenderContextProvider: React.FCWithChildren = ({ children }) => {
  const [bottomSheetContext, setBottomSheetContext] = React.useState<BottomSheetRenderContextType>({
    render: null,
  })

  return (
    <BottomSheetRenderContext.Provider value={bottomSheetContext}>
      <BottomSheetRenderDispatchContext.Provider value={setBottomSheetContext}>
        {children}
      </BottomSheetRenderDispatchContext.Provider>
    </BottomSheetRenderContext.Provider>
  )
}

export const useBottomSheetRender = () => {
  return useContext(BottomSheetRenderContext)
}

export const useBottomSheetRenderDispatch = () => {
  return useContext(BottomSheetRenderDispatchContext)
}

export interface OpenBottomSheetProps {
  render: Renderer
  style?: StackflowBottomSheetParams
}

export const useBottomSheet = () => {
  const { push } = useFlow()
  const setBottomSheetRender = useBottomSheetRenderDispatch()

  const openBottomSheet = useCallback(
    ({ render, style }: OpenBottomSheetProps) => {
      setBottomSheetRender({ render })

      push('BottomSheet', {
        ...style,
      })
    },
    [push, setBottomSheetRender]
  )

  return {
    openBottomSheet,
  }
}
