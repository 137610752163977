import axios from 'axios'
import { RequestParameters, Variables } from 'relay-runtime'

import { getUser } from '../bridge/bridgeInfo'
import { LOCAL_CONTENT_CURATOR_GATEWAY_API } from '../constants'

const CLIENT_NAME = 'Local-Explorer'

const fetcher = axios.create({ baseURL: LOCAL_CONTENT_CURATOR_GATEWAY_API })

fetcher.interceptors.request.use(
  async (config) => {
    const user = await getUser()

    if (user?.authToken) {
      config.headers['X-Auth-Token'] = user.authToken
      config.headers['x-user-id'] = user.id
    }

    config.headers['client-name'] = CLIENT_NAME

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

fetcher.interceptors.response.use(undefined, (error) => {
  return Promise.reject(error)
})

async function fetchGraphQL(operation: RequestParameters, variables: Variables) {
  const response = await fetcher.post('/graphql', {
    query: operation.text,
    variables,
  })

  return response.data
}

export default fetchGraphQL
