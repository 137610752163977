import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

type ThemeContext = {
  appTheme?: 'android' | 'cupertino'
  colorTheme?: 'light' | 'dark'
}

const AppScreenThemeContext = createContext<ThemeContext>({})

export const useAppScreenTheme = () => useContext(AppScreenThemeContext)

export const getAppTheme = () =>
  typeof window === 'undefined'
    ? 'android'
    : /iphone|ipad|ipod/i.test(navigator?.userAgent.toLowerCase() ?? '')
    ? 'cupertino'
    : 'android'

interface AppScreenThemeProviderProps {
  children: React.ReactNode
}
export const AppScreenThemeProvider: React.FC<AppScreenThemeProviderProps> = ({ children }) => {
  const prefersLight = typeof window !== 'undefined' && window.matchMedia('(prefers-color-scheme: light)')
  const prefersDark = typeof window !== 'undefined' && window.matchMedia('(prefers-color-scheme: dark)')

  console.log('#', document.documentElement.dataset.seedScaleColor)
  console.log('prefersLight', prefersLight)
  console.log('prefersDark', prefersDark)

  const [appTheme, setAppTheme] = useState<ThemeContext['appTheme']>(getAppTheme())
  const [colorTheme, setColorTheme] = useState<ThemeContext['colorTheme']>(
    (document.documentElement.dataset.seedScaleColor as 'light' | 'dark') ?? 'light'
  )

  const onChangeColorTheme = useCallback(() => {
    if (!prefersLight || !prefersDark) {
      return
    }

    const nextColorTheme = prefersLight.matches ? 'light' : 'dark'

    if (nextColorTheme !== colorTheme) {
      setColorTheme(nextColorTheme)
    }
  }, [colorTheme, prefersDark, prefersLight])

  useEffect(() => {
    if (!prefersLight || !prefersDark) {
      return
    }

    if (prefersLight.matches) {
      if ('addEventListener' in prefersLight) {
        prefersLight.addEventListener('change', onChangeColorTheme)
      } else if ('addListener' in prefersLight) {
        // @ts-ignore
        prefersLight.addListener(onChangeColorTheme)
      }
    } else if (prefersDark.matches) {
      if ('addEventListener' in prefersDark) {
        prefersDark.addEventListener('change', onChangeColorTheme)
      } else if ('addListener' in prefersDark) {
        // @ts-ignore
        prefersDark.addListener(onChangeColorTheme)
      }
    }
    onChangeColorTheme()
  }, [onChangeColorTheme, prefersDark, prefersLight])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setAppTheme(getAppTheme())
    }
  }, [])

  const value: ThemeContext = useMemo(
    () => ({
      appTheme,
      colorTheme,
    }),
    [appTheme, colorTheme]
  )
  return <AppScreenThemeContext.Provider value={value}>{children}</AppScreenThemeContext.Provider>
}
