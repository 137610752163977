module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-next-seo-virtual-fc0c0a65ea/0/cache/gatsby-plugin-next-seo-npm-1.10.0-e59979a254-ed02f47b12.zip/node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-164c7b7290/0/cache/gatsby-plugin-manifest-npm-5.8.0-26e7b141df-30f4ec20a4.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"theme_color":"#ffffff","background_color":"#ffffff","display":"minimal-ui","icon":"src/assets/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7dcc39f261bfd67ba48775b30250e407"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-prismic-previews-virtual-1fc4ab2964/0/cache/gatsby-plugin-prismic-previews-npm-5.3.0-9f21e2d179-42db4fb0cd.zip/node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"local-explorer","accessToken":"MC5aREEtMlJBQUFDSUEtNFFJ.bV3vv70777-9F--_vS_vv73vv73vv73vv73vv73vv73vv73vv70GJULvv71e77-9Ou-_vQHvv70i77-977-9FTIL","promptForAccessToken":true,"apiEndpoint":"https://local-explorer.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-1db7a086f5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
