import { StackflowReactPlugin } from '@stackflow/react'
import React from 'react'

import { BottomSheetRenderContextProvider } from './_bottomsheet/useBottomSheet'

export function bottomSheetPlugin(): StackflowReactPlugin {
  return () => ({
    key: 'LocalExplorerBottomSheetPlugin',
    wrapStack: ({ stack }) => {
      return <BottomSheetRenderContextProvider>{stack.render()}</BottomSheetRenderContextProvider>
    },
  })
}
