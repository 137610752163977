export const STAGE = process.env.NODE_ENV as 'development' | 'production' | 'alpha'
export const IS_PROD = STAGE === 'production'

/**
 * 개발용 karrotBridge.getAppInfo() */
export const DEFAULT_APP = process.env.GATSBY_DEFAULT_USER_AGENT
  ? Object.freeze({
      userAgent: process.env.GATSBY_DEFAULT_USER_AGENT,
    })
  : undefined

/**
 * 개발용 karrotBridge.getUserInfo()
 */
export const DEFAULT_USER = process.env.GATSBY_DEFAULT_USER_ID
  ? Object.freeze({
      id: process.env.GATSBY_DEFAULT_USER_ID ? Number(process.env.GATSBY_DEFAULT_USER_ID) : undefined,
      nickname: process.env.GATSBY_DEFAULT_USER_NICKNAME ?? undefined,
      authToken: process.env.GATSBY_DEFAULT_AUTH_TOKEN ?? undefined,
      phone: '',
      profileImage: '',
    })
  : undefined

/**
 * 개발용 karrotBridge.getRegionInfo()
 */
export const DEFAULT_REGION = process.env.GATSBY_DEFAULT_REGION_ID
  ? Object.freeze({
      id: process.env.GATSBY_DEFAULT_REGION_ID ? Number(process.env.GATSBY_DEFAULT_REGION_ID) : undefined,
      name: process.env.GATSBY_DEFAULT_REGION_NAME ?? undefined,
    })
  : undefined

/**
 * 개발용 karrotBridge.getCurrentPosition()
 */
export const DEFAULT_POSITION =
  process.env.GATSBY_DEFAULT_POSITION_LAT && process.env.GATSBY_DEFAULT_POSITION_LNG
    ? Object.freeze({
        lat: process.env.GATSBY_DEFAULT_POSITION_LAT,
        lng: process.env.GATSBY_DEFAULT_POSITION_LNG,
      })
    : undefined

/**
 * 로컬 스토리지에 Fallback App를 저장할 키
 */
export const LOCAL_STORAGE_FALLBACK_APP_KEY = `${STAGE}/v1/FALLBACK_app`

/**
 * 로컬 스토리지에 Fallback Region를 저장할 키
 */
export const LOCAL_STORAGE_FALLBACK_REGION_KEY = `${STAGE}/v1/FALLBACK_region`

/**
 * 로컬 스토리지에 Fallback User를 저장할 키
 */
export const LOCAL_STORAGE_FALLBACK_USER_KEY = `${STAGE}/v1/FALLBACK_user`

export const BASE_URI = 'https://local-explorer.karrotwebview.com'

export const LOCAL_STORAGE_STATE_KEY = `${STAGE}/v1/MY_DAANGN_STORY_STORAGE`

export const LOCAL_CONTENT_CURATOR_GATEWAY_API = IS_PROD
  ? 'https://business-graphql-gateway.kr.karrotmarket.com'
  : 'https://business-graphql-gateway.kr.karrotmarket.com'
