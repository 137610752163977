import React, { useMemo } from 'react'
import { RelayEnvironmentProvider } from 'react-relay'
import { Environment, Network, RecordSource, Store } from 'relay-runtime'

import fetchGraphQL from './fetchGraphQL'

const source = new RecordSource()
const store = new Store(source)

export function createEnvironment() {
  return new Environment({
    network: Network.create((operation, variables) => fetchGraphQL(operation, variables)),
    store,
  })
}

export const MyRelayEnvironmentProvider: React.FCWithChildren = (props) => {
  const environment = useMemo(() => {
    return createEnvironment()
  }, [])

  return <RelayEnvironmentProvider environment={environment}>{props.children}</RelayEnvironmentProvider>
}
